/* SegmentedButton.css */
.btn-primary.active,
.btn-primary:active,
.show>.btn-primary.dropdown-toggle {
  color: black;
  background-color: transparent; /* Set background-color to transparent to make it appear as black */
  border-color: black;
}

/* Override the default outline color to black */
.btn-outline-primary {
    color: black;
    border-color: black;
  }
  
  /* Override the hover state outline color to black */
  .btn-outline-primary:hover {
    color: black;
    border-color: black;
  }