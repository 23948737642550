a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
  }
  
  html {
    font-size: 14px;
  }
  @media (min-width: 768px) {
    html {
      font-size: 16px;
    }
    #LoginLogoutButton {
      margin-right: 7rem
    }
  }
  
  .box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
  }

.navbar-logo {
  position:absolute;
  right: 10px;
  top: 3px;
  width: 115px;
  height: auto;
  z-index: 1000;
}

@media (max-width: 768px) {
  .navbar-logo {
    display: none;
  }
}