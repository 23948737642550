@keyframes gear-rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
 
.spinning-gear {
    animation: gear-rotate 2s linear infinite;
}
 