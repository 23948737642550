
.custom-blue {
    /* background-color: #8cd4ed !important; */
    background-color: #1ba5c0 !important;
    color: black; /* You can adjust the text color as needed */
    border: none !important;
    /* Add other styles as needed */
}

.custom-gray{
    /* background-color: #8cd4ed !important; */
    background-color: #a9c3d9 !important;
    /* background-color: #1ba5c0 !important; */
    color: black;  
   
}
